import axios from 'axios';
import { JwtHelper } from './jwt-encrypt';
import { generatePermissionsHash } from './hash-generator';

interface PermissionsPayload {
  data: any;
  hashedPermissions: string;
}

const PermissionsRetriever = async (
  authToken: string,
  userEmail: string,
  permissionsAPIUrl: string
): Promise<PermissionsPayload> => {
  if (!authToken) {
    throw new Error('Auth token is required.');
  }

  if (!userEmail) {
    throw new Error('User email is required.');
  }

  if (!permissionsAPIUrl) {
    throw new Error('Permissions API URL is required.');
  }

  const jwtHelper = new JwtHelper();
  const decryptedToken = jwtHelper.decodeToken(authToken.toString() || '');
  const tokenUserGroups = decryptedToken['https://becklar.com/groups'];

  const fetchPermissions = async (): Promise<PermissionsPayload> => {
    const requestData = {
      userEmail,
      groups: tokenUserGroups,
      apiKeyId: 123, // Replace with a dynamic or environment-sourced value if applicable
    };

    try {
      const response = await axios.post(
        'https://n547hbcb64jigdpujprhfhskpa0xilpx.lambda-url.us-west-2.on.aws/permissions',
        requestData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          timeout: 5000, // Add a timeout of 5 seconds
        }
      );

      const data = response.data;

      console.log('Permissions Data:', data);

      const hashedPermissions = generatePermissionsHash(data);

      return {
        data,
        hashedPermissions,
      };
    } catch (error: any) {
      if (error.response) {
        // Server responded with a status code outside the range of 2xx
        console.error('API Error Response:', error.response.data);
        throw new Error(
          `Permissions API responded with status ${error.response.status}: ${
            error.response.data?.message || 'Unknown error'
          }`
        );
      } else if (error.request) {
        // Request was made but no response received
        console.error(
          'No response received from Permissions API:',
          error.request
        );
        throw new Error('No response from Permissions API.');
      } else {
        // Something else caused the error
        console.error('Error during API request:', error.message);
        throw new Error('Error fetching permissions: ' + error.message);
      }
    }
  };

  return await fetchPermissions();
};

export default PermissionsRetriever;
