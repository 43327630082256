import { registerApplication, start } from 'single-spa';
import { constructLayoutEngine, constructRoutes } from 'single-spa-layout';
import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import axios from 'axios';
import { getLambdaUrlAndAPIKey } from './settings/settings';
import PermissionsRetriever from './permissions-retriever';

const showSpinner = () => {
  document
    .getElementById('loadingSpinner')
    ?.style.setProperty('display', 'block');
};

const hideSpinner = () => {
  const spinner = document.getElementById('loadingSpinner');
  if (spinner) {
    spinner.style.display = 'none';
  } else {
    console.warn('Spinner element not found');
  }
};

const getEnvironment = () => {
  const origin = window.location.origin;
  return origin.includes('localhost') ? 'uat' : origin.split('.')[1] || null;
};

const env = getEnvironment();
const { url: menuLambdaUrl, apiKey: menuApiKey } = getLambdaUrlAndAPIKey(env);

let auth0Client = null;
let userEmail = null;

const fetchAuth0Config = async () => {
  const cachedConfig = sessionStorage.getItem('auth0Config');
  if (cachedConfig) return JSON.parse(cachedConfig);

  const response = await axios.get(`${menuLambdaUrl}/app-credentials`, {
    headers: { 'x-api-key': menuApiKey },
  });

  if (response.status === 200) {
    sessionStorage.setItem('auth0Config', JSON.stringify(response.data));
    return response.data;
  } else {
    throw new Error('Failed to fetch Auth0 config');
  }
};

const createAuth0ClientInstance = async () => {
  if (auth0Client) return auth0Client;

  const config = await fetchAuth0Config();
  auth0Client = await createAuth0Client({
    domain: config.Auth0_Domain,
    clientId: config.Auth0_ClientId,
    authorizationParams: {
      audience: config.Auth0_Audience,
      redirect_uri: window.location.origin,
      organization: config.Auth0_Organization,
      scope: 'openid profile email',
    },
    cacheLocation: 'localstorage',
  });
  return auth0Client;
};

const fetchApplications = async (authToken) => {
  //time to fetch the applications

  const startTime = performance.now();
  const response = await axios.get(`${menuLambdaUrl}/menu-items`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'x-api-key': menuApiKey,
    },
  });
  const endTime = performance.now();
  console.log(`Time taken to fetch applications: ${endTime - startTime}ms`);
  return response.data.filter((app) => app.AppUrl);
};

const updateImportMap = (applications) => {
  const imports = applications.reduce((map, app) => {
    const appId = app.AppIdentifier || app.Name;
    map[appId] = localStorage.getItem(appId) || app.AppUrl;
    return map;
  }, {});
  window.System.addImportMap({ imports });
};

const handleLayoutConstruction = async (client) => {
  showSpinner();
  setTimeout(() => {
    hideSpinner();
  }, 5000);
  try {
    const [authToken, user, applications] = await Promise.all([
      client.getTokenSilently(),
      client.getUser(),
      fetchApplications(await client.getTokenSilently()),
    ]);

    userEmail = user.email || null;

    const { hashedPermissions, data } = await PermissionsRetriever(
      authToken,
      userEmail,
      menuLambdaUrl
    );

    updateImportMap(applications);

    applications.forEach((app) => {
      registerApplication({
        name: app.Name,
        app: () => window.System.import(app.AppIdentifier || app.Name),
        activeWhen: [app.Route],
        customProps: {
          authToken,
          userEmail,
          hashedPermissions,
          permissions: data,
          handleLogout: () =>
            client.logout({
              logoutParams: { returnTo: window.location.origin },
            }),
          domElementGetter: () =>
            app.AppIdentifier === '@becklar/common-navigation-ui'
              ? document.getElementById('nav-container')
              : document.getElementById('single-spa-application'),
        },
      });
    });

    start();

    constructLayoutEngine({
      routes: constructRoutes({
        routes: [{ type: 'route', path: '/*', routes: [] }],
      }),
      applications: [],
    }).activate();
  } catch (error) {
    console.error('Error during layout construction:', error);
  } finally {
    hideSpinner();
  }
};

const initAuth0 = async () => {
  // Hide the spinner automatically after 10 seconds

  try {
    await Promise.all([
      System.import('@becklar/shell-ui'),
      createAuth0ClientInstance(),
    ]);

    const client = await createAuth0ClientInstance();

    if (
      window.location.search.includes('code=') &&
      window.location.search.includes('state=')
    ) {
      await client.handleRedirectCallback();
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    if (!(await client.isAuthenticated())) {
      await client.loginWithRedirect({
        authorizationParams: { redirect_uri: window.location.origin },
      });
    } else {
      await handleLayoutConstruction(client);
    }
  } catch (error) {
    console.error('Error initializing Auth0:', error);
  } finally {
    hideSpinner();

    // Clear the timeout if the spinner is already hidden
  }
};

initAuth0();
